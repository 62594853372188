import { computed, onMounted, ref } from "vue";

import XLSX, { WorkSheet } from "xlsx";
import Tabulator from "tabulator-tables";

import { getWaring, showError, showSuccess } from "@/libs/notification";
import { getOrderToImportTable } from "@/libs/validateOrder";
import { OrderImport } from "@/configs/orders";
import { addressService } from "@/services/address";
import { columnConfigs } from "./columns";
import { useStore } from "@/store";
import * as uuid from "uuid";
import dayjs from "dayjs";
import { orderService } from "@/services/order";

export const useImport: any = () => {
  const tableRef = ref<HTMLElement>();
  const tabulator = ref();
  const addresses = ref<any[]>([]);
  const address = ref(0);
  const successIds = ref<string[]>([]);
  const loading = ref(false);
  const importOrders = ref<OrderImport[]>([] as any);
  const store = useStore();
  const selectedOrder = ref<OrderImport>({});
  const countryAddresses = computed(() => store.state.main.countryAddresses);

  const fetchMyAddress = async () => {
    loading.value = true;
    const data = await addressService.getAddresses({
      page: 1,
      pageSize: 1000,
    });
    if (data?.result?.addresses) {
      addresses.value = data?.result?.addresses;
      const defaultAdd = addresses.value.find((item) => item.isDefault);
      address.value = defaultAdd?.id || addresses.value[0]?.id;
    }
    loading.value = false;
  };

  const editOrder = (order: any) => {
    selectedOrder.value = order;
    cash("#order-form").modal("show");
  };

  const checkImportOrderFile = (event: any) => {
    const input = event.target;
    // Ensure that you have a file before attempting to read it
    if (!(input.files && input.files[0] && countryAddresses.value.length > 0 && !loading.value)) {
      showError("Vui lòng chọn lại file");
      return;
    }
    loading.value = true;
    const toast = getWaring("Đang xử lý");
    toast.showToast();
    const file = input.files[0];
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e: any) => {
      /* Parse data */
      const bstr = e.target.result;
      const workBook = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = workBook.SheetNames[0];
      const sheet: WorkSheet = workBook.Sheets[wsname];

      /* loop through every cell manually */
      const range = XLSX.utils.decode_range(sheet["!ref"]!); // get the range
      let tempImportOrders: OrderImport[] = [];
      const currAddr: any = addresses.value.find((el: any) => el.id === address.value);
      for (let row = range.s.r; row <= range.e.r; ++row) {
        if (row < 8) {
          // note in file excel
          continue;
        }

        const customCodeRef = XLSX.utils.encode_cell({ c: 0, r: row });
        const receiverNameRef = XLSX.utils.encode_cell({ c: 1, r: row });
        const phoneRef = XLSX.utils.encode_cell({ c: 2, r: row });
        const addressRef = XLSX.utils.encode_cell({ c: 3, r: row });
        const provRef = XLSX.utils.encode_cell({ c: 4, r: row }); // construct A1 reference for cell
        const districRef = XLSX.utils.encode_cell({ c: 5, r: row });
        const areaRef = XLSX.utils.encode_cell({ c: 6, r: row });
        const goodsNameRef = XLSX.utils.encode_cell({ c: 7, r: row });
        const weightRef = XLSX.utils.encode_cell({ c: 8, r: row });
        const quantityRef = XLSX.utils.encode_cell({ c: 9, r: row });
        const codRef = XLSX.utils.encode_cell({ c: 11, r: row });
        const otherFeeRef = XLSX.utils.encode_cell({ c: 12, r: row });
        const signRef = XLSX.utils.encode_cell({ c: 16, r: row });
        const remarkRef = XLSX.utils.encode_cell({ c: 14, r: row });
        const goodsTypeRef = XLSX.utils.encode_cell({ c: 15, r: row });

        const customCodeCell = sheet[customCodeRef];
        const receiverNameCell = sheet[receiverNameRef];

        const phoneCell = sheet[phoneRef];
        const addressCell = sheet[addressRef];

        const provCell = sheet[provRef];
        const districCell = sheet[districRef];
        const areaCell = sheet[areaRef];

        const goodsNameCell = sheet[goodsNameRef];
        const goodsTypeCell = sheet[goodsTypeRef];
        const weightCell = sheet[weightRef];
        const quantityCell = sheet[quantityRef];
        const codCell = sheet[codRef];
        const otherFeeCell = sheet[otherFeeRef];
        const partialSignCell = sheet[signRef];
        const remarkCell = sheet[remarkRef];

        const excelIndex = row - 7;
        const customCode = customCodeCell?.v || `VNQ${excelIndex}`;
        const receiverName = receiverNameCell?.v;
        const receiverPhone = phoneCell?.v;
        const receiverProvince = provCell?.v;
        const receiverCity = districCell?.v;
        const receiverArea = areaCell?.v;
        const receiverAddress = addressCell?.v;
        const goodsName = goodsNameCell?.v;
        const goodsType = goodsTypeCell?.v;
        const weight = weightCell?.v;
        const numbers = quantityCell?.v || 1;
        const orderManner = "EXPRESS";
        const cod = codCell?.v || 0;
        const otherFees = otherFeeCell?.v || 0;
        const partialSign = partialSignCell?.v?.trim() === "x";
        const payMethod = "PP_PM";
        const remark = remarkCell?.v;

        if (!receiverNameCell?.v) {
          // exit when empty row
          break;
        }

        const orderImport = getOrderToImportTable(
          {
            excelIndex,
            customCode,
            receiverName,
            receiverPhone,
            receiverProvince,
            receiverCity,
            receiverArea,
            receiverAddress,
            goodsName,
            goodsType,
            weight,
            numbers,
            orderManner,
            cod,
            otherFees,
            partialSign,
            payMethod,
            remark,
          },
          countryAddresses.value
        );

        tempImportOrders.push({
          ...orderImport,
          senderAddress: {
            value: currAddr?.address,
            displayValue: currAddr?.address,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
          senderArea: {
            value: currAddr?.area,
            displayValue: currAddr?.area,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
          senderCity: {
            value: currAddr?.district,
            displayValue: currAddr?.district,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
          senderName: {
            value: currAddr?.name,
            displayValue: currAddr?.name,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
          senderProvince: {
            value: currAddr?.province,
            displayValue: currAddr?.province,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
          senderPhone: {
            value: currAddr?.phone,
            displayValue: currAddr?.phone,
            error: currAddr ? undefined : "Thông tin người gửi hàng trống",
          },
        });
      }
      tempImportOrders = tempImportOrders
        .map((item) => {
          const errorCnt = Object.values(item).filter((el) => {
            if (typeof el === "number") return false;
            return el?.error;
          }).length;
          return {
            ...item,
            priority: {
              value: errorCnt > 0 ? 1 : 0,
            },
          };
        })
        .sort((a, b) => b.priority.value! - a.priority.value!);
      importOrders.value = tempImportOrders;
      tabulator.value.setData(tempImportOrders);
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    toast.hideToast();
    loading.value = false;
  };

  async function createOrders(listOrders: OrderImport[]) {
    if (loading.value) {
      return;
    }

    let errMeg;
    for (let i = 0; i < listOrders.length; i++) {
      const errors = Object.values(listOrders[i]).filter((el) => {
        if (typeof el === "number") return false;
        return el?.error;
      });
      if (errors.length > 0) {
        errMeg = `Số thứ tự Excel: ${listOrders[i].excelIndex?.value} lỗi`;
        break;
      }
    }
    if (errMeg) {
      showError(errMeg);
      return;
    }

    const orders = listOrders.map((item) => {
      const order = {
        customCode: item.customCode?.value || uuid(),
        sendDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // sender
        senderAddress: item.senderAddress?.value,
        senderArea: item.senderArea?.value,
        senderDistrict: item.senderCity?.value,
        senderName: item.senderName?.value,
        senderProvince: item.senderProvince?.value,
        senderPhone: item.senderPhone?.value,
        // receiver
        receiverPhone: item.receiverPhone?.value,
        receiverAddress: item.receiverAddress?.value,
        receiverArea: item.receiverArea?.value,
        receiverDistrict: item.receiverCity?.value,
        receiverName: item.receiverName?.value,
        receiverProvince: item.receiverProvince?.value,
        // Goods
        goodsName: item.goodsName?.value,
        // goodsType: item.goodsType?.value  ,
        weight: item.weight?.value?.toString(),
        quantity: item.numbers?.value,
        // goodsValue: item.goodsValue?.toString(),
        // Delivery
        cod: item.cod?.value?.toString() || "0",
        insurance: item.insurance?.value ? "1" : "0",
        partialSign: item.partialSign?.value ? "1" : "0",
        remark: item.remark?.value || "",
        payMethod: item.payMethod?.value,
        excelIndex: item.excelIndex?.value,
        goodsValue: "0",
      };
      return order;
    });
    const toast = getWaring("Đang xử lý");
    toast.showToast();
    loading.value = true;
    try {
      const data = await orderService.createOrders({ orders });
      if (data?.result?.successIds?.length) {
        const codes = data?.result?.successCodes || [];
        importOrders.value = importOrders.value.filter((el) => !codes.includes(el.customCode?.value));
        tabulator.value.setData(importOrders);
        successIds.value = data?.result?.successIds;
        cash("#order-create-success-modal").modal("show");
      }
    } catch (error) {
      showError(error);
    } finally {
      toast.hideToast();
      loading.value = false;
    }
  }

  // Redraw table onresize
  const reInitOnResizeWindow = () => {
    window.addEventListener("resize", () => {
      tabulator.value.redraw();
    });
  };

  const initTabulator: any = () => {
    tabulator.value = new Tabulator(tableRef.value, {
      paginationSize: 8,
      pagination: "local",
      paginationSizeSelector: true,
      tooltipGenerationMode: "hover",
      placeholder: "Chưa chọn file tải",
      columns: columnConfigs,
      rowClick(e: any, row: any) {
        editOrder(row.getData());
      },
    });
  };

  const updateTabulator: any = (order: any) => {
    tabulator.value.updateData([getOrderToImportTable(order, countryAddresses.value)]);
  };

  const deleteRowTabulator: any = () => {
    const rows = tabulator.value.getSelectedData();
    const excelIndexes = rows.map((item: any) => item.excelIndex.value);
    importOrders.value = importOrders.value.filter((item: any) => !excelIndexes.includes(item.excelIndex.value));
    tabulator.value.deleteRow(rows.map((item: { id: any }) => item.id));
  };

  const createOrderTabulator: any = () => {
    const rows = tabulator.value.getSelectedData();
    createOrders(rows);
  };

  const createAllOrderTabulator: any = () => {
    createOrders(importOrders.value);
  };

  onMounted(() => {
    initTabulator();
    fetchMyAddress();
    reInitOnResizeWindow();
    store.dispatch("main/SET_COUNTRY_ADDRESS");
  });

  return {
    tableRef,
    address,
    importOrders,
    selectedOrder,
    checkImportOrderFile,
    addresses,
    createOrders,
    loading,
    deleteRowTabulator,
    createOrderTabulator,
    createAllOrderTabulator,
    updateTabulator,
    successIds,
  };
};
