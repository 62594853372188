<template>
  <div id="order-form" class="modal">
    <div class="modal__content modal__content--xxl p-10 text-center">
      <div class="mb-5">
        <OrderForm ref="formRef" :order="order" :readonly="readonly" />
      </div>
      <a
        v-if="!readonly"
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-1 text-white"
        @click="save"
      >
        Xác nhận
      </a>
      <a
        href="javascript:;"
        class="button w-24 mr-4 inline-block bg-theme-2 text-gray-500"
        data-dismiss="modal"
      >
        Huỷ
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, toRefs, watch } from "vue";
import { Order, OrderImport } from "@/configs/orders";
import OrderForm from "@/components/order-form/Main.vue";
import _ from "lodash";

export default defineComponent({
  name: "OrderModal",
  components: {
    OrderForm,
  },
  props: {
    orderImport: {
      type: Object as PropType<OrderImport>,
      default: {} as any,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null as any);
    const { orderImport } = toRefs(props);

    const order = ref<Order>({});

    const save = () => {
      if (formRef.value?.validateForm()) {
        emit("update-order", formRef.value?.formData);
        cash("#order-form").modal("hide");
      }
    };

    watch(orderImport, (newValue: any) => {
      order.value = _.mapValues(newValue, (item) => {
        return item.value;
      });
    });

    return {
      save,
      order,
      formRef,
    };
  },
});
</script>
