
import { defineComponent, PropType } from "vue";
import { Order } from "@/configs/orders";
import { useForm } from "./useForm";
import { FORM_BOXES } from "./configs";
import vClickOutside from "@/libs/click-outside";
import AddressPicker from "@/components/address-picker/Main.vue";
import SenderModal from "@/components/sender-modal/Main.vue";

export default defineComponent({
  name: "OrderForm",
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    AddressPicker,
    SenderModal,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
    },
    small: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_BOXES,
    };
  },
  setup(props) {
    const {
      formData,
      validate,
      validateForm,
      updateFormData,
      senderZone,
      receiverZone,
      selectSender,
      resetForm,
    } = useForm(props);

    return {
      formData,
      validate,
      validateForm,
      updateFormData,
      senderZone,
      receiverZone,
      selectSender,
      resetForm,
    };
  },
  methods: {
    showSenderAddressPicker() {
      if (cash("#sender-address-picker").hasClass("hidden")) {
        cash("#sender-address-picker").removeClass("hidden");
      } else {
        cash("#sender-address-picker").addClass("hidden");
      }
    },
    showSenderModal() {
      cash("#sender-modal").modal("show");
    },
    hideSenderAddressPicker() {
      cash("#sender-address-picker").addClass("hidden");
    },
    showReceiverAddressPicker() {
      if (cash("#receiver-address-picker").hasClass("hidden")) {
        cash("#receiver-address-picker").removeClass("hidden");
      } else {
        cash("#receiver-address-picker").addClass("hidden");
      }
    },
    hideReceiverAddressPicker() {
      cash("#receiver-address-picker").addClass("hidden");
    },
  },
});
