<template>
  <div class="grid grid-cols-12 gap-6">
    <div
      v-for="(box, index) in FORM_BOXES"
      :key="index"
      class="intro-y box col-span-12 lg:col-span-6"
    >
      <div
        class="flex flex-col sm:flex-row items-center p-3 border-b border-gray-200 dark:border-dark-5"
      >
        <UserIcon v-if="box.icon === 'UserIcon'" class="mr-1" />
        <ShoppingBagIcon
          v-else-if="box.icon === 'ShoppingBagIcon'"
          class="mr-1"
        />
        <TruckIcon v-else class="mr-1" />
        <h2 class="font-medium text-base mr-auto">{{ box.title }}</h2>
        <div
          class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
          v-if="box.icon === 'ShoppingBagIcon'"
        >
          <div class="mr-3">Giao 1 phần</div>
          <input
            class="show-code input border"
            type="checkbox"
            v-model="validate.partialSign.$model"
            :readonly="readonly"
          />
        </div>
        <div
          class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
          v-if="box.title === 'Người gửi'"
        >
          <button
            class="button px-2 mr-1 mb-2 bg-theme-1 text-white"
            @click="showSenderModal"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <LogInIcon class="w-4 h-4" />
            </span>
          </button>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-x-2 pl-3 pr-3 pb-3">
        <div
          v-for="(item, index) in box.items"
          :key="index"
          class="input-form mt-2"
          :class="item.class"
        >
          <div v-if="item.type === 'text'">
            <label
              :for="item.field"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              {{ item.label }}
            </label>
            <div class="relative mt-1">
              <input
                :id="item.field"
                v-model.trim="validate[item.field].$model"
                type="text"
                :name="item.field"
                class="input w-full border"
                :class="{
                  'input--sm': small,
                  'border-theme-6': validate[item.field].$error,
                  'border-theme-9':
                    !validate[item.field].$error && validate[item.field].$dirty,
                }"
                :placeholder="`Vui lòng nhập ${item.label}`"
                :readonly="item.readonly || readonly"
              />
              <div
                class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate[item.field].$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate[item.field].$error && validate[item.field].$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
            </div>
            <template v-if="validate[item.field].$error">
              <div class="text-left ml-2 text-theme-6">
                {{ validate[item.field].$errors[0].$message }}
              </div>
            </template>
          </div>
          <div v-if="item.type === 'select'">
            <label
              :for="item.field"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              {{ item.label }}
            </label>
            <div class="relative mt-1">
              <select
                :id="item.field"
                v-model.trim="validate[item.field].$model"
                :name="item.field"
                class="input w-full border appearance-none"
                :class="{
                  'input--sm': small,
                  'border-theme-6': validate[item.field].$error,
                  'border-theme-9':
                    !validate[item.field].$error && validate[item.field].$dirty,
                }"
                :placeholder="`Vui lòng nhập ${item.label}`"
                :readonly="item.readonly || readonly"
              >
                <option
                  v-for="option in item.options"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <div
                class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate[item.field].$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate[item.field].$error && validate[item.field].$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
            </div>

            <template v-if="validate[item.field].$error">
              <div class="text-left ml-2 text-theme-6">
                {{ validate[item.field].$errors[0].$message }}
              </div>
            </template>
          </div>
          <div v-if="item.type === 'senderZone'">
            <label
              :for="item.field"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              {{ item.label }}
            </label>
            <div
              class="relative mt-1"
              v-click-outside="hideSenderAddressPicker"
            >
              <input
                :id="item.field"
                :value="senderZone"
                type="text"
                class="input w-full border"
                :class="{
                  'input--sm': small,
                  'border-theme-6': validate.senderArea.$error,
                  'border-theme-9':
                    !validate.senderArea.$error && validate.senderArea.$dirty,
                }"
                :placeholder="`Vui lòng chọn ${item.label}`"
                :readonly="true"
                @click="showSenderAddressPicker"
              />
              <AddressPicker
                id="sender-address-picker"
                @province-selected="
                  (val) =>
                    updateFormData({
                      senderProvince: val,
                      senderCity: undefined,
                      senderArea: undefined,
                    })
                "
                @district-selected="
                  (val) =>
                    updateFormData({ senderCity: val, senderArea: undefined })
                "
                @area-selected="(val) => updateFormData({ senderArea: val })"
              />
              <div
                class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate.senderArea.$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate.senderArea.$error && validate.senderArea.$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
            </div>
            <template v-if="validate.senderArea.$error">
              <div class="text-left ml-2 text-theme-6">
                {{ validate.senderArea.$errors[0].$message }}
              </div>
            </template>
          </div>
          <div v-if="item.type === 'receiverZone'">
            <label
              :for="item.field"
              class="form-label w-full flex flex-col sm:flex-row"
            >
              {{ item.label }}
            </label>
            <div
              class="relative mt-1"
              v-click-outside="hideReceiverAddressPicker"
            >
              <input
                :id="item.field"
                :value="receiverZone"
                type="text"
                class="input w-full border"
                :class="{
                  'input--sm': small,
                  'border-theme-6': validate.receiverArea.$error,
                  'border-theme-9':
                    !validate.receiverArea.$error &&
                    validate.receiverArea.$dirty,
                }"
                :placeholder="`Vui lòng chọn ${item.label}`"
                :readonly="true"
                @click="showReceiverAddressPicker"
              />
              <AddressPicker
                id="receiver-address-picker"
                @province-selected="
                  (val) =>
                    updateFormData({
                      receiverProvince: val,
                      receiverCity: undefined,
                      receiverArea: undefined,
                    })
                "
                @district-selected="
                  (val) =>
                    updateFormData({
                      receiverCity: val,
                      receiverArea: undefined,
                    })
                "
                @area-selected="(val) => updateFormData({ receiverArea: val })"
              />
              <div
                class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center"
              >
                <XCircleIcon
                  v-if="validate.receiverArea.$error"
                  class="w-4 h-4 text-theme-6"
                />
                <CheckCircleIcon
                  v-if="
                    !validate.receiverArea.$error &&
                    validate.receiverArea.$dirty
                  "
                  class="w-4 h-4 text-theme-9"
                />
              </div>
            </div>
            <template v-if="validate.receiverArea.$error">
              <div class="text-left ml-2 text-theme-6">
                {{ validate.receiverArea.$errors[0].$message }}
              </div>
            </template>
          </div>
          <div v-if="item.type === 'hidden'">
            <input
              :id="item.field"
              v-model.trim="validate[item.field].$model"
              type="hidden"
              :name="item.field"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <SenderModal @sender-select="selectSender" />
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Order } from "@/configs/orders";
import { useForm } from "./useForm";
import { FORM_BOXES } from "./configs";
import vClickOutside from "@/libs/click-outside";
import AddressPicker from "@/components/address-picker/Main.vue";
import SenderModal from "@/components/sender-modal/Main.vue";

export default defineComponent({
  name: "OrderForm",
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    AddressPicker,
    SenderModal,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
    },
    small: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FORM_BOXES,
    };
  },
  setup(props) {
    const {
      formData,
      validate,
      validateForm,
      updateFormData,
      senderZone,
      receiverZone,
      selectSender,
      resetForm,
    } = useForm(props);

    return {
      formData,
      validate,
      validateForm,
      updateFormData,
      senderZone,
      receiverZone,
      selectSender,
      resetForm,
    };
  },
  methods: {
    showSenderAddressPicker() {
      if (cash("#sender-address-picker").hasClass("hidden")) {
        cash("#sender-address-picker").removeClass("hidden");
      } else {
        cash("#sender-address-picker").addClass("hidden");
      }
    },
    showSenderModal() {
      cash("#sender-modal").modal("show");
    },
    hideSenderAddressPicker() {
      cash("#sender-address-picker").addClass("hidden");
    },
    showReceiverAddressPicker() {
      if (cash("#receiver-address-picker").hasClass("hidden")) {
        cash("#receiver-address-picker").removeClass("hidden");
      } else {
        cash("#receiver-address-picker").addClass("hidden");
      }
    },
    hideReceiverAddressPicker() {
      cash("#receiver-address-picker").addClass("hidden");
    },
  },
});
</script>
