import { computed, ref, toRefs, watch } from "vue";

import _ from "lodash";
import useVuelidate from "@vuelidate/core";

import { Order } from "@/configs/orders";
import { showError } from "@/libs/notification";
import { between, decimal, helpers, integer, maxValue, minValue, required } from "@vuelidate/validators";

export const useForm: any = (props: any) => {
  const { order } = toRefs(props);
  const formData = ref<Order>(_.cloneDeep(order.value));

  const selectSender = (address: any) => {
    formData.value.senderCity = address.district;
    formData.value.senderProvince = address.province;
    formData.value.senderArea = address.area;
    formData.value.senderName = address.name;
    formData.value.senderPhone = address.phone;
    formData.value.senderAddress = address.address;
  };

  // Validator Fn
  const phoneFormat = (value: any) => {
    return !value || /^(0|\+84)\d{9}$/.test(value);
  };

  const senderDistrictRequire = () => {
    return helpers.req(formData.value.senderCity);
  };

  const receiverDistrictRequire = () => {
    return helpers.req(formData.value.receiverCity);
  };

  const senderProvinceRequire = () => {
    return helpers.req(formData.value.senderProvince);
  };

  const receiverProvinceRequire = () => {
    return helpers.req(formData.value.receiverProvince);
  };

  const rules = {
    receiverName: {
      required: helpers.withMessage("Tên người nhận bắt buộc nhập", required),
    },
    receiverPhone: {
      required: helpers.withMessage("Số điện thoại người nhận bắt buộc nhập", required),
      phoneFormat: helpers.withMessage("Sai định dạng số điện thoại", phoneFormat),
    },
    receiverAddress: {
      required: helpers.withMessage("Địa chỉ người nhận bắt buộc nhập", required),
    },
    senderName: {
      required: helpers.withMessage("Tên người gửi bắt buộc nhập", required),
    },
    senderPhone: {
      required: helpers.withMessage("Số điện thoại người gửi bắt buộc nhập", required),
      phoneFormat: helpers.withMessage("Sai định dạng số điện thoại", phoneFormat),
    },
    senderAddress: {
      required: helpers.withMessage("Địa chỉ người gửi bắt buộc nhập", required),
    },
    goodsName: {
      required: helpers.withMessage("Tên hàng hóa bắt buộc nhập", required),
    },
    weight: {
      required: helpers.withMessage("Trọng lượng bắt buộc nhập", required),
      decimal: helpers.withMessage("Trọng lượng phải là số", decimal),
      between: helpers.withMessage("Trọng lượng phải lớn hơn 0 và nhỏ hơn 200", between(0, 200)),
    },
    numbers: {
      required: helpers.withMessage("Số kiện bắt buộc nhập", required),
      integer: helpers.withMessage("Số kiện phải là số nguyên", integer),
      minValue: helpers.withMessage("Số kiện phải lớn hơn 0", minValue(0)),
    },
    cod: {
      required: helpers.withMessage("Tiền Cod bắt buộc nhập", required),
      integer: helpers.withMessage("Tiền Cod phải là số nguyên", integer),
      minValue: helpers.withMessage("Tiền Cod phải lớn hơn 0", minValue(0)),
      maxValue: helpers.withMessage("Tiền Cod phải nhỏ hơn 50.000.000", maxValue(50000000)),
    },
    customCode: {},
    payMethod: {
      required: helpers.withMessage("Phương thức thanh toán bắt buộc nhập", required),
    },
    orderManner: {
      required: helpers.withMessage("Dịch vụ bắt buộc nhập", required),
    },
    otherFees: {
      integer: helpers.withMessage("Phí khác phải là số nguyên", integer),
      minValue: helpers.withMessage("Phí khác phải lớn hơn 0", minValue(0)),
    },
    goodsType: {
      required: helpers.withMessage("Loại hàng hóa bắt buộc nhập", required),
    },
    remark: {
      required: helpers.withMessage("Ghi chú bắt buộc nhập", required),
    },
    senderArea: {
      senderProvinceRequire: helpers.withMessage("Tỉnh/Thành Phố gửi bắt buộc nhập", senderProvinceRequire),
      senderDistrictRequire: helpers.withMessage("Quận/Huyện gửi bắt buộc nhập", senderDistrictRequire),
      required: helpers.withMessage("Khu vực gửi bắt buộc nhập", required),
    },
    receiverArea: {
      receiverProvinceRequire: helpers.withMessage("Tỉnh/Thành Phố nhận bắt buộc nhập", receiverProvinceRequire),
      receiverDistrictRequire: helpers.withMessage("Quận/Huyện nhận bắt buộc nhập", receiverDistrictRequire),
      required: helpers.withMessage("Khu vực nhận bắt buộc nhập", required),
    },
    partialSign: {},
    freight: {},
    status: {},
  };

  const validate = useVuelidate(rules, formData, { $autoDirty: true });

  const updateFormData = (newData: any) => {
    formData.value = {
      ...formData.value,
      ...newData,
    };
  };

  const validateForm = () => {
    validate.value.$touch();
    if (validate.value.$error) {
      showError("Đã xảy ra lỗi");
      return false;
    } else {
      return true;
    }
  };

  const resetForm = () => {
    formData.value = {
      goodsType: "Hàng hóa",
      orderManner: "EXPRESS",
      payMethod: "PP_PM",
    }
    validate.value.$reset();
  };

  watch(order, (newValue) => {
    formData.value = _.cloneDeep(newValue);
    validate.value.$touch();
  });

  const senderZone = computed(() => {
    return `${formData.value.senderProvince || ""}/${formData.value.senderCity || ""}/${
      formData.value.senderArea || ""
    }`;
  });

  const receiverZone = computed(() => {
    return `${formData.value.receiverProvince || ""}/${formData.value.receiverCity || ""}/${
      formData.value.receiverArea || ""
    }`;
  });

  return {
    formData,
    validate,
    validateForm,
    updateFormData,
    senderZone,
    receiverZone,
    selectSender,
    resetForm
  };
};
