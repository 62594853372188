
import { defineComponent, onMounted, ref } from "vue";
import { addressService } from "@/services/address";

export default defineComponent({
  name: "SenderModal",
  setup(props, { emit }) {
    const addresses = ref<any[]>([]);

    const fetchMyAddress = async () => {
      const data = await addressService.getAddresses({
        page: 1,
        pageSize: 1000,
      });
      if (data?.result?.addresses) {
        addresses.value = data?.result?.addresses;
      }
    };

    const select = (address: any) => {
      emit("sender-select", address);
      cash("#sender-modal").modal("hide");
    };

    onMounted(() => {
      fetchMyAddress();
    });

    return { addresses, select };
  },
});
