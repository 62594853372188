<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">Lên đơn hàng loạt</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <input
          type="file"
          ref="file"
          style="display: none"
          @change="checkImportOrderFile"
        />
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="
            () => {
              $refs.file.value = '';
              $refs.file.click();
            }
          "
        >
          <LoadingIcon
            color="white"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loading"
          />
          <UploadIcon class="w-4 h-4 mr-2" v-else /> Tải lên hàng loạt
        </button>
        <button
          class="button text-white bg-theme-11 shadow-md mr-2 flex items-center"
          @click="downloadSample"
        >
          <LoadingIcon
            color="white"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loading"
          />
          <DownloadCloudIcon class="w-4 h-4 mr-2" v-else /> Tải về file mẫu
        </button>
        <button
          class="button text-white bg-theme-9 shadow-md mr-2 flex items-center"
          @click="downloadArea"
        >
          <LoadingIcon
            color="white"
            icon="spinning-circles"
            class="w-4 h-4 mr-2"
            v-if="loading"
          />
          <DownloadIcon class="w-4 h-4 mr-2" v-else /> Thông tin khu vực
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col">
        <div class="flex items-center">
          <label class="w-30 mr-2 flex items-center">
            <UserIcon class="w-4 h-4 mr-2" /> Người gửi
          </label>
          <select
            class="input w-80 mt-2 sm:mt-0 sm:w-auto border"
            v-model="address"
          >
            <option
              v-for="address in addresses"
              :value="address.id"
              :key="address.id"
            >
              {{
                `${address.name} - ${address.phone} - ${address.province} - ${address.district} - ${address.area}`
              }}
            </option>
          </select>
          <button
            class="button inline-block border border-theme-12 text-theme-12 shadow-md ml-auto mr-2 flex items-center"
            v-if="importOrders.length > 0"
            @click="deleteRowTabulator"
          >
            <LoadingIcon
              color="white"
              icon="spinning-circles"
              class="w-4 h-4 mr-2"
              v-if="loading"
            />
            <Trash2Icon class="w-4 h-4 mr-2" v-else /> Xóa lựa chọn
          </button>
          <div class="dropdown mr-0" v-if="importOrders.length > 0">
            <button
              class="dropdown-toggle button inline-block border border-theme-9 text-theme-9 shadow-md ml-auto flex items-center"
            >
              <LoadingIcon
                color="white"
                icon="spinning-circles"
                class="w-4 h-4 mr-2"
                v-if="loading"
              />
              <PlusIcon class="w-4 h-4 mr-2" v-else />
              Tạo đơn
            </button>
            <div class="dropdown-box w-40">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="createOrderTabulator"
                >
                  Tạo đơn đã chọn
                </a>
                <a
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="createAllOrderTabulator"
                >
                  Tạo toàn bộ đơn
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-2 table-report table-report--tabulator"
          style="height: 60vh"
        ></div>
        <div class="box intro-y mt-2 ">
          <span> Total: {{ importOrders.length }}</span>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <OrderModal :orderImport="selectedOrder" @update-order="updateTabulator" />
  <OrderCreateSuccessModal :ids="successIds" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useImport } from "./useImport";
import OrderModal from "@/components/order-modal/Main.vue";
import OrderCreateSuccessModal from "@/components/order-create-success-modal/Main.vue";

export default defineComponent({
  components: {
    OrderModal,
    OrderCreateSuccessModal,
  },
  setup() {
    const {
      tableRef,
      importOrders,
      addresses,
      address,
      checkImportOrderFile,
      selectedOrder,
      createOrders,
      loading,
      updateTabulator,
      createOrderTabulator,
      createAllOrderTabulator,
      deleteRowTabulator,
      successIds,
    } = useImport();

    return {
      tableRef,
      importOrders,
      addresses,
      address,
      selectedOrder,
      checkImportOrderFile,
      createOrders,
      loading,
      updateTabulator,
      createOrderTabulator,
      createAllOrderTabulator,
      deleteRowTabulator,
      successIds,
    };
  },
  methods: {
    downloadSample() {
      window.open(`/filemaunew.xlsx`, `_blank`);
    },
    downloadArea() {
      window.open(
        `https://vip.jtexpress.vn/yuenan-vip-interface/api/download?fileNames=areaInfo/AreaInformation.xls`,
        `_blank`
      );
    },
  },
});
</script>
