
import { defineComponent } from "vue";
import { useImport } from "./useImport";
import OrderModal from "@/components/order-modal/Main.vue";
import OrderCreateSuccessModal from "@/components/order-create-success-modal/Main.vue";

export default defineComponent({
  components: {
    OrderModal,
    OrderCreateSuccessModal,
  },
  setup() {
    const {
      tableRef,
      importOrders,
      addresses,
      address,
      checkImportOrderFile,
      selectedOrder,
      createOrders,
      loading,
      updateTabulator,
      createOrderTabulator,
      createAllOrderTabulator,
      deleteRowTabulator,
      successIds,
    } = useImport();

    return {
      tableRef,
      importOrders,
      addresses,
      address,
      selectedOrder,
      checkImportOrderFile,
      createOrders,
      loading,
      updateTabulator,
      createOrderTabulator,
      createAllOrderTabulator,
      deleteRowTabulator,
      successIds,
    };
  },
  methods: {
    downloadSample() {
      window.open(`/filemaunew.xlsx`, `_blank`);
    },
    downloadArea() {
      window.open(
        `https://vip.jtexpress.vn/yuenan-vip-interface/api/download?fileNames=areaInfo/AreaInformation.xls`,
        `_blank`
      );
    },
  },
});
