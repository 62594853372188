<template>
  <div id="sender-modal" class="modal" style="z-index: 9000">
    <div class="modal__content modal__content--xxl p-10 text-center">
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Tên</th>
              <th class="whitespace-nowrap">Mặc định</th>
              <th class="text-center whitespace-nowrap">Điện thoại</th>
              <th class="text-center whitespace-nowrap">Thành phố</th>
              <th class="text-center whitespace-nowrap">Quận Huyện</th>
              <th class="text-center whitespace-nowrap">Khu vực</th>
              <th class="text-center whitespace-nowrap">Địa chỉ</th>
              <th class="text-center whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="address in addresses" :key="address.id" class="intro-x">
              <td>{{ address.name }}</td>
              <td>{{ address.isDefault }}</td>
              <td>{{ address.phone }}</td>
              <td>{{ address.province }}</td>
              <td>{{ address.district }}</td>
              <td>{{ address.area }}</td>
              <td>{{ address.address }}</td>
              <td class="table-report__action">
                <button
                  class="button bg-theme-1 text-white"
                  @click="select(address)"
                >
                  Chọn
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { addressService } from "@/services/address";

export default defineComponent({
  name: "SenderModal",
  setup(props, { emit }) {
    const addresses = ref<any[]>([]);

    const fetchMyAddress = async () => {
      const data = await addressService.getAddresses({
        page: 1,
        pageSize: 1000,
      });
      if (data?.result?.addresses) {
        addresses.value = data?.result?.addresses;
      }
    };

    const select = (address: any) => {
      emit("sender-select", address);
      cash("#sender-modal").modal("hide");
    };

    onMounted(() => {
      fetchMyAddress();
    });

    return { addresses, select };
  },
});
</script>
