
import { defineComponent, PropType, ref, toRefs, watch } from "vue";
import { Order, OrderImport } from "@/configs/orders";
import OrderForm from "@/components/order-form/Main.vue";
import _ from "lodash";

export default defineComponent({
  name: "OrderModal",
  components: {
    OrderForm,
  },
  props: {
    orderImport: {
      type: Object as PropType<OrderImport>,
      default: {} as any,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null as any);
    const { orderImport } = toRefs(props);

    const order = ref<Order>({});

    const save = () => {
      if (formRef.value?.validateForm()) {
        emit("update-order", formRef.value?.formData);
        cash("#order-form").modal("hide");
      }
    };

    watch(orderImport, (newValue: any) => {
      order.value = _.mapValues(newValue, (item) => {
        return item.value;
      });
    });

    return {
      save,
      order,
      formRef,
    };
  },
});
