import validator from "validator";
import { CountryAddress } from "@/configs/jtTypes";
import { OrderImport } from "@/configs/orders";
import { v4 as uuid } from "uuid";

export const validateRequiredField = (text: any) => {
  text = text?.toString();
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  return {
    value: text,
    displayValue: text,
    error: undefined,
  };
};

export const validatePhone = (text: any) => {
  text = text?.toString()?.trim();
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  if (!/^(0|\+84)\d{9}$/.test(text)) {
    return {
      value: text,
      displayValue: text,
      error: "Số điện thoại không đúng định dạng ",
    };
  }
  return {
    value: text,
    displayValue: text,
    error: undefined,
  };
};

export const validateProvince = (text: any, listProvince: CountryAddress[]) => {
  text = text?.toString();
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  const province = listProvince.find(
    (el) =>
      el?.shorthandName?.toLowerCase().trim() === text?.toLowerCase().trim()
  );

  if (!province) {
    return {
      value: undefined,
      displayValue: text,
      error: "Không tìm thấy tỉnh, thành phố nhận kiện",
    };
  }
  return {
    value: province.name,
    displayValue: province.shorthandName,
    error: undefined,
  };
};

export const validateDistrict = (text: any, listDistrict: CountryAddress[]) => {
  text = text?.toString();
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  const district = listDistrict.find(
    (el) =>
      el?.shorthandName?.toLowerCase().trim() === text?.toLowerCase().trim()
  );
  if (!district) {
    return {
      value: undefined,
      displayValue: text,
      error: "Không tìm thấy quận, huyện nhận kiện",
    };
  }
  return {
    value: district.name,
    displayValue: district.shorthandName,
    error: undefined,
  };
};

export const validateArea = (text: any, listArea: CountryAddress[]) => {
  text = text?.toString();
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  const area = listArea.find(
    (el) =>
      el?.shorthandName?.toLowerCase().trim() === text?.toLowerCase().trim() ||
      el?.name?.toLowerCase().trim() === text?.toLowerCase().trim()
  );
  if (!area) {
    return {
      value: undefined,
      displayValue: text,
      error: "Không tìm thấy xã, phường nhận kiện",
    };
  }
  return {
    value: area.name,
    displayValue: area.shorthandName,
    error: undefined,
  };
};

export const validateNumber = (
  text: any,
  options?: { min?: number; max?: number }
) => {
  text = text?.toString();
  const { min, max } = options || {};
  if (!text) {
    return {
      value: undefined,
      displayValue: "Phần này bắt buộc điền",
      error: "Phần này bắt buộc điền",
    };
  }
  if (!validator.isNumeric(text?.toString())) {
    return {
      value: text,
      displayValue: text,
      error: "Phần này sai định dạng",
    };
  }
  if (min && parseFloat(text) < min) {
    return {
      value: parseFloat(text),
      displayValue: text,
      error: `Giá trị nhỏ nhất là ${min}`,
    };
  }
  if (max && parseFloat(text) > max) {
    return {
      value: parseFloat(text),
      displayValue: text,
      error: `Giá trị lớn nhất là ${max}`,
    };
  }
  return {
    value: text,
    displayValue: text,
    error: undefined,
  };
};

export const validateBoolean = (text: boolean) => {
  return {
    value: text,
    displayValue: text ? "Có" : "Không",
    error: undefined,
  };
};

export const getOrderToImportTable = (
  order: any,
  countryAddress: CountryAddress[]
) => {
  const {
    excelIndex,
    customCode,
    receiverName,
    receiverPhone,
    receiverProvince,
    receiverCity,
    receiverArea,
    receiverAddress,
    goodsName,
    goodsType,
    weight,
    numbers,
    orderManner,
    cod,
    otherFees,
    partialSign,
    payMethod,
    remark,
  } = order;
  const listDistrict =
    countryAddress.find(
      (el) =>
        el.shorthandName?.toLowerCase() ===
        receiverProvince?.toLowerCase().trim()
    )?.childNodes || [];
  const listArea =
    listDistrict.find(
      (el) =>
        el.shorthandName?.toLowerCase() === receiverCity?.toLowerCase().trim()
    )?.childNodes || [];

  const orderImport: OrderImport = {
    priority: {
      value: 0,
    },
    id: excelIndex,
    excelIndex: {
      value: excelIndex,
      displayValue: excelIndex.toString(),
    },
    customCode: {
      value: customCode,
      displayValue: customCode,
    },
    receiverName: validateRequiredField(receiverName),
    receiverPhone: validatePhone(receiverPhone),
    receiverProvince: validateProvince(receiverProvince, countryAddress),
    receiverCity: validateDistrict(receiverCity, listDistrict),
    receiverArea: validateArea(receiverArea, listArea),
    receiverAddress: validateRequiredField(receiverAddress),
    goodsName: validateRequiredField(goodsName),
    goodsType: validateRequiredField(goodsType),
    weight: validateNumber(weight, { min: 0 }),
    numbers: validateNumber(numbers, { min: 1 }),
    orderManner: {
      value: orderManner,
      displayValue: orderManner,
      error: undefined,
    },
    cod: validateNumber(cod, { min: 0 }),
    otherFees: validateNumber(otherFees, { min: 0 }),
    partialSign: validateBoolean(partialSign),
    payMethod: validateRequiredField(payMethod),
    remark: validateRequiredField(remark),
  };
  return orderImport;
};
