export const columnConfigs = [
  {
    formatter: "rowSelection",
    titleFormatter: "rowSelection",
    hozAlign: "center",
    headerSort: false,
    cellClick: function (e: any, cell: any) {
      cell.getRow().toggleSelect();
    },
  },
  {
    title: "STT Excel",
    minWidth: 80,
    responsive: 0,
    field: "excelIndex",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Mã đơn KH",
    minWidth: 80,
    responsive: 0,
    field: "customCode",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Người nhận",
    minWidth: 120,
    responsive: 0,
    field: "receiverName",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Điện thoại",
    minWidth: 120,
    responsive: 0,
    field: "receiverPhone",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Tỉnh nhận",
    minWidth: 120,
    responsive: 0,
    field: "receiverProvince",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Quận huyện",
    minWidth: 120,
    responsive: 0,
    field: "receiverCity",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Khu vực nhận",
    minWidth: 120,
    responsive: 0,
    field: "receiverArea",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Địa chỉ nhận",
    minWidth: 120,
    responsive: 0,
    field: "receiverAddress",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Tên hàng hóa",
    minWidth: 120,
    responsive: 0,
    field: "goodsName",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Loại hàng",
    minWidth: 120,
    responsive: 0,
    field: "goodsType",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Cân nặng",
    minWidth: 120,
    responsive: 0,
    field: "weight",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Số lượng",
    minWidth: 120,
    responsive: 0,
    field: "numbers",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Dịch vụ",
    minWidth: 120,
    responsive: 0,
    field: "orderManner",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Tiền COD",
    minWidth: 120,
    responsive: 0,
    field: "cod",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Phí khác",
    minWidth: 120,
    responsive: 0,
    field: "otherFees",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Giao 1 phần",
    minWidth: 120,
    responsive: 0,
    field: "partialSign",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "PTTT",
    minWidth: 120,
    responsive: 0,
    field: "payMethod",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
  {
    title: "Ghi chú",
    minWidth: 120,
    responsive: 0,
    field: "remark",
    vertAlign: "middle",
    headerSort: false,
    formatter(cell: any) {
      const value = cell.getValue();
      if (value.error)
        return `<span class="text-theme-1">${value.displayValue}</span>`;
      return value.displayValue;
    },
    tooltip(cell: any) {
      return cell.getValue()?.error;
    },
  },
];
